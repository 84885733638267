import React, { useState } from "react";
import { useAppContext } from "src/context/AppContext";
import LoggedOutDetails from "./LoggedOutDetails";
import LoggedInDetails from "./LoggedInDetails";
import { formatDistanceToNow } from "date-fns";
import { sanitizeHtmlOption } from "src/utilities/constants";
import sanitizeHtml from "sanitize-html";
import { copyToClipboard, formatStringDate, generateUrl, renderCurrency, renderSuccessMessage } from "src/utilities/functions";
import logo from "src/assets/fellowship.png";
import {
	ClockIcon,
	PriceTagIcon,
	PaymentIcon,
	UserCheckIcon,
	RightArrowIcon,
	CopyIcon,
	ShareIcon,
	WarningIcon,
	CloseIcon,
	CheckCircleIcon,
	OptionsIcon,
} from "src/assets/svg";
import { Loader, Modal, ParticipantCard } from "src/components";
import { ApplyToFellowship, MyProposals, RateCreator, SubmitProposal } from "src/modals";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Contract from "./Contract";
import Popup from "reactjs-popup";
import { getEngagementDetails } from "src/api";
import { useQuery } from "@tanstack/react-query";

const FellowshipDetails = () => {
	const { user, token } = useAppContext();

	return user && token ? <LoggedInDetails /> : <LoggedOutDetails />;
};

export const NotAppliedDisplay = ({ fellowship, isJudgeOrCreator }) => {
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();
	const navigate = useNavigate();

	const url = generateUrl(`/programs/${fellowship?.uuid}`);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () =>
		!user || !token ? navigate({ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }) : setIsModalOpen(true);

	return (
		<>
			<div
				className="text-white bg-no-repeat bg-cover py-28 min-h-[47rem] bg-blend-overlay"
				style={{ backgroundImage: `url(${fellowship?.cover_image || logo}), linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6))` }}
			>
				<div className="z-10">
					<h1 className="text-[4.8rem] font-Roobert font-bold max-w-[400px] text-balance leading-[115%] mb-12">{fellowship?.title}</h1>
					<div
						className="*:font-medium *:text-[1.6rem] *:leading-[1.65] max-w-[600px] truncate-overflow [--max-lines:4] [--line-height:2.64rem] mb-12"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
						}}
					/>
					<div className="flex gap-7 flex-wrap items-center text-[1.4rem]">
						<p className="flex items-center gap-3">
							<ClockIcon /> {formatDistanceToNow(fellowship?.created_at || "2024-06-27T08:45:05.000000Z")}
						</p>
						<p className="flex items-center gap-3 underline">
							Created by: {fellowship?.creator_first_name} {fellowship?.creator_last_name}
						</p>
					</div>
				</div>
			</div>
			<div className="pt-24 pb-12 max-w-[clamp(1200px,80vw,1600px)]">
				<div className="pb-24">
					<div className="flex flex-wrap items-center justify-between gap-8 mb-12">
						<h2 className="text-[3.2rem] text-headerText font-Roobert font-semibold">About the Program</h2>
						{!isJudgeOrCreator && (
							<button className="primary-btn" onClick={openModal}>
								Apply Now <RightArrowIcon />
							</button>
						)}
					</div>
					<div
						className="*:text-[1.8rem] *:text-justify *:leading-[1.65] *:text-bodyText *:mb-10"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
						}}
					/>
				</div>
				<div className="grid gap-8 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] py-12 border-y border-[#919BAF66]">
					<InfoCard
						text={formatStringDate(fellowship?.closing_date || "2024-07-04")}
						icon={<ClockIcon />}
						information="Application Closes"
					/>
					<InfoCard text={renderCurrency(fellowship?.project_budget)} icon={<PriceTagIcon />} information="Budget" />
					<InfoCard text={fellowship?.payment_frequency?.replace("-", " ")} icon={<PaymentIcon />} information="Payment Frequency" />
					<InfoCard text={fellowship?.level_of_expertise} icon={<UserCheckIcon />} information="Expertise" />
				</div>
				<div className="py-12 border-b border-[#919BAF66]">
					<p className="flex items-center gap-3 text-[1.6rem] font-medium text-headerText">
						Program type: <span className="text-bodyText">{fellowship?.type}</span>
					</p>
				</div>
			</div>
			<div className="pb-24 max-w-[clamp(1200px,80vw,1600px)]">
				<h2 className="text-[3.2rem] text-headerText font-Roobert font-semibold mb-14">Skills and expertise</h2>
				<div className="flex gap-6 max-w-[550px] flex-wrap">
					{fellowship?.skills_required?.map((skill, index) => (
						<p key={index} className="text-[1.4rem] rounded-full font-medium text-bodyText px-6 py-4 bg-[#EFEFEF]">
							{skill}
						</p>
					))}
				</div>
			</div>
			<div className="pb-24 max-w-[clamp(1200px,80vw,1600px)]">
				<h2 className="text-[3.2rem] text-headerText font-Roobert font-semibold mb-14">Program link</h2>
				<p className="text-[1.4rem] mb-6 font-medium text-bodyText bg-[#EFEFEF] rounded-full px-6 py-4 w-fit">{url}</p>
				<div className="flex items-center gap-6">
					<button
						className="text-primary flex items-center gap-3 text-[1.6rem] font-semibold"
						onClick={() => copyToClipboard(url).then(() => renderSuccessMessage("URL copied"))}
					>
						<CopyIcon /> Copy Link
					</button>
					<button className="text-primary flex items-center gap-3 text-[1.6rem] font-semibold">
						<ShareIcon /> Share
					</button>
				</div>
			</div>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Apply to fellowship" size="xl">
				<ApplyToFellowship fellowshipId={fellowship?.uuid} />
			</Modal>
		</>
	);
};

export const AppliedDisplay = ({ fellowship, judges, isSelected, hasSubmittedProposal, hasBeenEngaged, engagementStatus }) => {
	const url = generateUrl(`/programs/${fellowship?.uuid}`);

	const [setModalOpen, setSetModalOpen] = useState(false);
	const [isRateCreatorModalOpen, setIsRateCreatorModalOpen] = useState(false);

	const { data, isFetching } = useQuery({
		queryKey: ["engagement", fellowship?.uuid],
		queryFn: () => getEngagementDetails(fellowship?.uuid),
		enabled: engagementStatus !== "accepted",
	});

	const engagement = data?.data;
	const engagementPaymentType = engagement?.payment_type;

	if (isFetching) return <Loader />;

	if (hasBeenEngaged && !["rejected", "accepted"].includes(engagementStatus)) return <Contract fellowship={fellowship} />;

	return (
		<>
			<div className="[--bleed-color:#F1F4F6] bg-[var(--bleed-color)] full-bleed py-6 md:py-20">
				<h1 className="font-Roobert font-bold text-[2.4rem] md:text-[4rem] text-headerText">{fellowship?.title}</h1>
			</div>
			<div className="grid pt-24 pb-24">
				<RenderAlert isSelected={isSelected} hasSubmittedProposal={hasSubmittedProposal} fellowship={fellowship} />
				<div className="flex items-center justify-end gap-5 mb-14">
					{engagementPaymentType === "milestone" && (
						<Link to="milestones" className="px-10 py-5 ml-auto primary-btn">
							View Milestones
						</Link>
					)}
					{(hasSubmittedProposal || fellowship?.status === "closed") && (
						<Popup
							trigger={
								<button className="flex items-center justify-center p-4 border border-gray-200 rounded-full">
									<OptionsIcon />
								</button>
							}
							on="hover"
							arrow={false}
							position="bottom right"
							closeOnDocumentClick
							keepTooltipInside="#root"
						>
							<div className="flex flex-col gap-2 min-w-[170px]">
								<ul className="flex flex-col gap-2 p-2">
									{hasSubmittedProposal && (
										<li>
											<button
												onClick={() => setSetModalOpen(true)}
												className="hover:bg-gray-100 px-6 py-4 text-bodyText text-[1.6rem] font-medium w-full text-left"
											>
												My proposal
											</button>
										</li>
									)}
									{fellowship?.status === "closed" && (
										<li>
											<button
												onClick={() => setIsRateCreatorModalOpen(true)}
												className="hover:bg-gray-100 px-6 py-4 text-bodyText text-[1.6rem] font-medium w-full text-left"
											>
												Rate fellowship creator
											</button>
										</li>
									)}
								</ul>
							</div>
						</Popup>
					)}
				</div>
				<div className="grid min-[950px]:grid-cols-[1fr_max-content] gap-16">
					<div>
						<div className="pb-12">
							<div className="pb-24">
								<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-semibold mb-8">DESCRIPTION</h2>
								<div
									className="*:text-[1.6rem] *:text-justify *:leading-[1.55] *:text-bodyText *:mb-10"
									dangerouslySetInnerHTML={{
										__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
									}}
								/>
							</div>
							<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-semibold mb-8">DETAILS</h2>
							<div className="grid gap-8 grid-cols-[repeat(auto-fit,minmax(155px,1fr))] py-12">
								<InfoCard
									text={formatStringDate(fellowship?.closing_date || "2024-07-04")}
									icon={<ClockIcon />}
									information="Application Closes"
								/>
								<InfoCard text={renderCurrency(fellowship?.project_budget)} icon={<PriceTagIcon />} information="Budget" />
								<InfoCard
									text={fellowship?.payment_frequency?.replace("-", " ")}
									icon={<PaymentIcon />}
									information="Payment Frequency"
								/>
								<InfoCard text={fellowship?.level_of_expertise} icon={<UserCheckIcon />} information="Expertise" />
							</div>
							<div className="py-12">
								<p className="flex items-center gap-3 text-[1.6rem] font-medium text-headerText">
									Program type: <span className="text-bodyText">{fellowship?.type}</span>
								</p>
							</div>
						</div>
						<div className="pb-24">
							<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-semibold mb-14">Skills and expertise</h2>
							<div className="flex gap-6 max-w-[550px] flex-wrap">
								{fellowship?.skills_required?.map((skill, index) => (
									<p key={index} className="text-[1.4rem] rounded-full font-medium text-bodyText px-6 py-4 bg-[#EFEFEF]">
										{skill}
									</p>
								))}
							</div>
						</div>
						<div>
							<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-semibold mb-14">Program link</h2>
							<p className="text-[1.4rem] mb-6 font-medium text-bodyText bg-[#EFEFEF] rounded-full px-6 py-4 w-fit">{url}</p>
							<div className="flex items-center gap-6">
								<button
									className="text-bodyText flex items-center gap-3 text-[1.6rem] font-semibold"
									onClick={() => copyToClipboard(url).then(() => renderSuccessMessage("URL copied"))}
								>
									<CopyIcon className="text-bodyText" /> Copy Link
								</button>
								<button className="text-bodyText flex items-center gap-3 text-[1.6rem] font-semibold">
									<ShareIcon className="text-bodyText" /> Share
								</button>
							</div>
						</div>
					</div>
					<div className="min-[950px]:w-[clamp(270px,40vw,350px)] grid gap-10 max-[950px]:grid-cols-[repeat(auto-fit,minmax(280px,1fr))] h-fit">
						<ParticipantStack title="Judges" judges={judges} />
					</div>
				</div>
			</div>
			<Modal isOpen={setModalOpen} setIsOpen={setSetModalOpen} size="l" title="My Proposal">
				<MyProposals fellowship={fellowship} />
			</Modal>
			<Modal isOpen={isRateCreatorModalOpen} size="sm" setIsOpen={setIsRateCreatorModalOpen} title={`Rate ${fellowship?.title} creator`}>
				<RateCreator creator={engagement?.applicant} fellowship={fellowship?.uuid} />
			</Modal>
		</>
	);
};

export const InfoCard = ({ text, icon, information }) => {
	return (
		<div className="text-headerText">
			<p className="flex items-center capitalize gap-3 text-[1.6rem] font-medium">
				{icon} {text}
			</p>
			<p className="text-[1.4rem] capitalize text-[#778499]">{information}</p>
		</div>
	);
};

const ParticipantStack = ({ title, judges }) => {
	return (
		<div className="grid divide-y divide-[#EAEBF0] px-8 border border-[#EAEBF0] rounded-lg">
			<p className="text-[1.6rem] text-[#272D37] font-medium py-5">{title}</p>
			{judges?.map((user, index) => (
				<ParticipantCard key={index} user={user} />
			))}
		</div>
	);
};

const RenderAlert = ({ isSelected, hasSubmittedProposal, fellowship }) => {
	const [show, setShow] = useState(true);
	const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

	if (hasSubmittedProposal) return null;
	if (!show) return null;

	if (!!isSelected)
		return (
			<>
				<div className="bg-[#F0FAF0] mb-10 md:mb-16 items-start flex justify-between p-10 rounded-2xl">
					<div>
						<p className="text-black text-[1.6rem] mt-1 font-medium *:inline mb-3">
							<CheckCircleIcon className="text-[#2D8A39] -mt-1 mr-3 min-w-[20px] max-w-[20px] stroke-[3px] min-h-[20px] max-h-[20px]" />
							Congratulations! You have been accepted into this program.
						</p>
						<button className="flex gap-4 text-[#2D8A39] font-semibold items-center" onClick={() => setIsSubmitModalOpen(true)}>
							Submit proposal <RightArrowIcon />
						</button>
					</div>
					<button onClick={() => setShow(false)}>
						<CloseIcon className="text-bodyText2" />
					</button>
				</div>
				<Modal isOpen={isSubmitModalOpen} setIsOpen={setIsSubmitModalOpen} title="Submit Proposal" size="l">
					<SubmitProposal fellowship={fellowship} />
				</Modal>
			</>
		);

	return (
		<div className="bg-[#FFF8EB] mb-10 md:mb-16 items-center flex justify-between p-10 rounded-2xl">
			<p className="text-black text-[1.6rem] font-medium *:inline">
				<WarningIcon className="text-[#EEA23E] -mt-2 mr-3" /> Your application is still under review
			</p>
			<button onClick={() => setShow(false)}>
				<CloseIcon className="text-bodyText2" />
			</button>
		</div>
	);
};

export default FellowshipDetails;
