import React, { Suspense, useEffect, useRef } from "react";
import Carousel1 from "src/assets/carousel-1.png";
import { Loader } from "..";
import Message from "./Message";
import { useMessagingContext } from "src/context/MessagingContext";
import { useQuery } from "@tanstack/react-query";
import { getCreatorMessages, getMessages } from "src/api";
import MessageRoomInput from "./MessageRoomInput";

const MessageRoom = ({ creator }) => {
	return (
		<div className="mb-[2.5rem] mt-[2rem] overflow-y-auto *:px-[2rem] grid relative grid-rows-[max-content,1fr,max-content]">
			<Suspense fallback={<Loader />}>
				<RenderArea creator={creator} />
			</Suspense>
		</div>
	);
};

const RenderArea = ({ creator }) => {
	const { setIsOpen, fellowshipID: fellowshipId, applicantID: applicantId, creatorID: creatorId, roomDetails } = useMessagingContext();
	const messagesEndRef = useRef(null);

	const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

	const { data } = useQuery({
		queryKey: [creator ? "creator-messages" : "messages", fellowshipId, applicantId, creatorId],
		queryFn: creator ? () => getCreatorMessages({ fellowshipId, applicantId }) : () => getMessages({ fellowshipId, creatorId }),
		refetchInterval: 1000 * 5,
		enabled: !!fellowshipId && (!!applicantId || !!creatorId),
		suspense: true,
	});

	useEffect(() => {
		scrollToBottom();
	}, [data]);

	const messages = data?.data; //?.data;

	if (!roomDetails) return <h1>Please select a room</h1>;

	return (
		<>
			<div className="flex items-center justify-between gap-4 border-b pb-[1.5rem] border-[#919BAF66] bg-white sticky top-0 h-fit">
				<div className="flex items-center gap-4">
					<img src={Carousel1} className="w-[45px] aspect-square rounded-full object-cover" alt="carousel-1" />
					<div className="text-left">
						<p className="text-headerText font-Roobert text-[1.6rem] font-semibold leading-none mb-1">
							{creator
								? `${roomDetails?.applicant?.first_name} ${roomDetails?.applicant?.last_name}`
								: `${roomDetails?.creator?.first_name} ${roomDetails?.creator?.last_name}`}
						</p>
						<p className="text-bodyText text-[1.4rem]">{roomDetails?.fellowship?.title}</p>
					</div>
				</div>
				<button className="p-2 text-white rounded-md bg-primary lg:hidden" type="button" onClick={() => setIsOpen((prevState) => !prevState)}>
					open side
				</button>
			</div>
			<div className="flex justify-end flex-col pt-[2rem] pb-[5rem]">
				{messages?.map((message, index) => (
					<Message message={message} key={index} ref={index === messages.length - 1 ? messagesEndRef : null} />
				))}
			</div>
			<MessageRoomInput creator={creator} messageListRef={messagesEndRef} />
		</>
	);
};

export default MessageRoom;
