/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { AddIcon, ExpandIcon, NotificationsIcon } from "src/assets/svg";
import { Modal } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { CreateFellowship, Notifications } from "src/modals";
import { getInitials } from "src/utilities/functions";

const TopNav = () => {
	const { user, token } = useAppContext();

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

	return (
		<nav className="bg-[#F1F4F6] [--bleed-color:#F1F4F6] bg-[var(--bleed-color)] full-bleed">
			<div className="flex flex-wrap items-center font-medium text-[1.6rem] text-[#1F1D1D] justify-between py-[1.5rem] mx-auto max-[800px]:max-w-[calc(100vw-6rem)] max-w-[calc(100vw-12rem)]">
				<Link to="/" className="flex items-center space-x-3">
					<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D]">Kohort</h1>
				</Link>
				<div className="hidden w-full max-md:order-last md:block md:w-auto" id="navbar-dropdown">
					<ul className="flex flex-col md:space-x-8 md:flex-row md:mt-0">
						{/* <li className="mob-display">
							<button
								id="dropdownNavbarLink"
								data-dropdown-toggle="dropdownNavbar"
								className="flex items-center justify-between w-full md:w-auto"
							>
								<span className="flex items-center gap-3">
									Programs
									<DownIcon />
								</span>
							</button>
							<div id="dropdownNavbar" className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow">
								<ul
									className="p-4 grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] max-sm:max-w-[350px] gap-6"
									aria-labelledby="dropdownLargeButton"
								>
									{fellowshipTypes?.map(({ name, uuid, icon, description }) => (
										<li key={uuid}>
											<Link
												to={{ pathname: "/programs", search: `?type=${uuid}` }}
												replace
												className="flex gap-3 p-4 rounded-lg hover:bg-gray-100"
											>
												<div>
													<img src={icon} className="w-[1.8rem] h-[1.8rem] mt-2 text-primary" alt="" />
												</div>
												<div>
													<p className="text-headerText font-Roobert font-medium text-[1.8rem]">{name}</p>
													<p className="text-bodyText text-[1.45rem]">{description}</p>
												</div>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</li> */}
						<li className="mob-display">
							<Link to="/contact" className="block">
								Contact
							</Link>
						</li>
						{user && token ? (
							<button onClick={() => setIsCreateModalOpen(true)} className="justify-center w-full secondary-btn md:hidden">
								Create program
							</button>
						) : (
							<>
								<li className="md:hidden mob-display">
									<Link to="/login" className="block">
										Login
									</Link>
								</li>
								<Link to="/register" className="justify-center w-full secondary-btn md:hidden">
									Create your account
								</Link>
							</>
						)}
					</ul>
				</div>
				<ActionArea setIsCreateModalOpen={setIsCreateModalOpen} />
			</div>
			<Modal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} title="Create a program">
				<CreateFellowship />
			</Modal>
		</nav>
	);
};

const ActionArea = ({ setIsCreateModalOpen }) => {
	const { user, token, setUser, setToken } = useAppContext();

	const logOut = () => {
		setToken("");
		setUser({});
	};

	if (user && token)
		return (
			<div className="flex items-center gap-4">
				<button className="secondary-btn max-md:hidden" onClick={() => setIsCreateModalOpen(true)}>
					<AddIcon /> Create program
				</button>
				<Popup
					trigger={
						<button className="w-[48px] hover:bg-gray-200 aspect-square rounded-full flex items-center justify-center">
							<NotificationsIcon />
						</button>
					}
					on="hover"
					position="bottom right"
					closeOnDocumentClick
					keepTooltipInside="#root"
				>
					<Notifications />
				</Popup>
				<Popup
					trigger={
						<button className="bg-headerText w-[48px] aspect-square rounded-full text-[1.8rem] font-semibold text-white flex items-center justify-center">
							{getInitials(user?.first_name, user?.last_name)}
						</button>
					}
					on="hover"
					position="bottom center"
					closeOnDocumentClick
					keepTooltipInside="#root"
				>
					<div className="flex flex-col gap-2 max-w-[300px]">
						<div className="flex items-center gap-4 px-8 pt-6 pb-3">
							<div className="bg-headerText w-[48px] aspect-square rounded-full text-[1.8rem] font-semibold text-white flex items-center justify-center">
								{getInitials(user?.first_name, user?.last_name)}
							</div>
							<div>
								<p className="text-headerText text-[1.6rem] leading-[1.1] font-Roobert font-semibold">
									{user?.first_name} {user?.last_name}
								</p>
								<p className="text-bodyText2 text-[1.4rem] font-Roobert">{user?.email}</p>
							</div>
						</div>
						<ul className="flex flex-col gap-6 px-8 py-6 border-y border-lightGray">
							<li>
								<Link to="/my-programs" className="hover:underline text-bodyText text-[1.6rem] font-medium">
									My programs
								</Link>
							</li>
							<li>
								<Link to="/messages" className="hover:underline text-bodyText text-[1.6rem] font-medium">
									Messages
								</Link>
							</li>
							<li>
								<Link to="/profile" className="hover:underline text-bodyText text-[1.6rem] font-medium">
									Edit profile
								</Link>
							</li>
							<li>
								<Link to="/creator" className="hover:underline text-bodyText text-[1.6rem] font-medium">
									Creator Dashboard
								</Link>
							</li>
							<li>
								<Link to="/judge" className="hover:underline text-bodyText text-[1.6rem] font-medium">
									Judges Dashboard
								</Link>
							</li>
							<li>
								<button onClick={logOut} className="hover:underline text-bodyText text-[1.6rem] font-medium">
									Logout
								</button>
							</li>
						</ul>
						<button onClick={() => setIsCreateModalOpen(true)} className="px-8 pt-3 pb-6 text-left">
							<div className="flex items-center justify-between mb-2">
								<p className="text-headerText text-[1.6rem] font-Roobert font-semibold">Create program</p>
								<ExpandIcon className="text-headerText" />
							</div>
							<p className="text-bodyText2 text-[1.4rem] font-Roobert">
								Kohort is an online platform where individuals and organisations can come together to collaborate and execute diverse
								projects.
							</p>
						</button>
					</div>
				</Popup>
			</div>
		);

	return (
		<div className="flex items-center gap-3">
			<Link to="/login" className="px-4 py-2 max-md:hidden">
				Login
			</Link>
			<Link to="/register" className="secondary-btn max-md:hidden">
				Create your account
			</Link>
		</div>
	);
};

export default TopNav;
